import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import Gallery from '../components/Gallery'


import mypic from '../assets/images/Mary.jpg'
import resume from '../assets/files/resume.pdf'


const About = () => (
    <Layout>
        <Helmet>
            <title>Hi, I'm Mary! - Mary Ojo</title>
            <meta name="description" content="About Page" />
        </Helmet>

        <div id="main" className="alt">
            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h1>Meet Me</h1>
                    </header>
                    <div className='grid-wrapper'>
                        <div className='col-12 layout1 '>
                            <div className="image left mypic col-3"><img className="pic" src="https://ik.imagekit.io/fsa8lvz/Mary-622d71449ea6cf8d46cdb6c8fa135dc5_UKcdUgDPk.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1661270983752" alt="" /></div>
                            <div>
                            <div className='col-9 intro'>
                            <p>Hi, I'm Mary. There are so many interesting things I like about Technology, but the most intriguing thing for me is how I can see a problem around me and solve it using my technical skills. I care a lot about people, and for this reason, I like to build products that are human-centered. I'm looking forward to building more innovative and human-centered products!
                            </p>
                            <p>It's so nice to have you here! Send me a message if you have any feedback, comments or questions.</p>
                            <p>Thank you!</p> 
                            <ul className="actions">
                                <li><a href={resume} className="button icon fa-eye" target="_blank">View resume</a></li>
                            </ul>
                            </div>
                            
                            </div>
                       
                        </div>
                    </div>
                    <hr className="major" />
                    
                        <div className="box alt">
                                    <h3>My Skills</h3>
                                    {/* <Skills/> */}
                                    <div className='skillset intro'>
                                        <div>HTML/CSS</div>
                                        <div>JavaScript</div>
                                        <div>React.js</div>
                                        <div>Figma</div>
                                        <div>Framer</div>
                                        <div>Gatsby.js</div>
                                        <div>Tensorflow.js</div>
                                        <div>Linux/UNIX</div>
                                        <div>HTML</div>
                                        <div>GitHub</div>
                                        <div>Azure</div>
                                        <div>Firebase</div>
                                        <div>TailwindCSS</div>
                                        <div>Bootstrap</div>

                                    </div>
                                    </div>
                                    {/* <hr className="major" /> */}

                        {/* <div className="box alt">
                                    <h3>Picture Stories</h3>
                                    <Gallery/>
                        </div> */}
                    {/* </div> */}
                </div>
            </section>
        </div>

    </Layout>
)

export default About